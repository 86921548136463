'use client';

import { type ComponentPropsWithoutRef, type ReactNode, useCallback } from 'react';

import cn from 'classnames';

import type { FilteredPackage } from '@/infra/types/voyageInfo/package';
import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import * as Drawer from '@/components/elements/Drawer';
import tagmanager from '@/tagmanager';

import SailingCards from './SailingCards/SailingCards';
import { DatesLabel, Header, HeaderWithImage, PackageDescription } from './components';

import classes from './ViewMoreDates.module.scss';

const { trackCloseViewMoreDates } = tagmanager.tracker.voyageDetails;

type TViewMoreDatesProps = {
  packageData: FilteredPackage;
  withDescription?: boolean;
};

export const ViewMoreDates = ({ packageData, withDescription }: TViewMoreDatesProps) => (
  <div className={cn(classes.root, classes.withBorder)}>
    <Header />
    {withDescription && <PackageDescription packageData={packageData} />}
    <DatesLabel alignCenter packageData={packageData} />
    <SailingCards packageData={packageData} />
  </div>
);

type TViewMoreDatesFlyoutProps = ComponentPropsWithoutRef<typeof Drawer.Root> & {
  children?: ReactNode;
  ctaSlot?: ReactNode;
  onItemClick?: (sailing: SailingFromPackages) => void;
  packageData: FilteredPackage;
};

export const ViewMoreDatesFlyout = ({
  children,
  ctaSlot,
  onItemClick,
  onOpenChange,
  packageData,
  ...props
}: TViewMoreDatesFlyoutProps) => {
  const onOpenChangeHandler = useCallback(
    (isOpen: boolean) => {
      trackCloseViewMoreDates();
      onOpenChange?.(isOpen);
    },
    [onOpenChange],
  );

  const preventDefault = useCallback((event: Event) => event.preventDefault(), []);

  return (
    <Drawer.Root direction="right" onOpenChange={onOpenChangeHandler} {...props}>
      {children && <Drawer.Trigger asChild>{children}</Drawer.Trigger>}
      <Drawer.Content
        className={classes.flyoutContent}
        onCloseAutoFocus={!children ? preventDefault : undefined}
        role="document"
      >
        <Drawer.Title asChild>
          <HeaderWithImage packageData={packageData} />
        </Drawer.Title>
        <PackageDescription packageData={packageData} />
        {ctaSlot && <div className={classes.ctaSlot}>{ctaSlot}</div>}
        <DatesLabel packageData={packageData} />
        <SailingCards onItemClick={onItemClick} packageData={packageData} />
      </Drawer.Content>
    </Drawer.Root>
  );
};
