import dynamic from 'next/dynamic';
import { useCallback } from 'react';

import type { TLink } from '@/infra/types/common';
import type { TOptional } from '@/types/common';

import { PromoBannerPlaceId, PromoBannerType, type TWithOnClick } from '@/ducks/promoBanners/types';
import tagmanager from '@/tagmanager';

import Billboard from '../Billboard';
import Double from '../Double';
import Featurette from '../Featurette';
import Leaderboard from '../Leaderboard';
import Marquee from '../Marquee';
import usePromoBannersProps from './usePromoBannersProps';

import './PromoBannerPlace.scss';

export const BANNERS = {
  [PromoBannerType.billboard]: dynamic(() => Promise.resolve(Billboard)),
  [PromoBannerType.double]: dynamic(() => Promise.resolve(Double)),
  [PromoBannerType.featurette]: dynamic(() => Promise.resolve(Featurette)),
  [PromoBannerType.leaderboard]: dynamic(() => Promise.resolve(Leaderboard)),
  [PromoBannerType.marquee]: dynamic(() => Promise.resolve(Marquee)),
};

type TProps = TWithOnClick<
  {
    isFrameless?: TOptional<boolean>;
    placeId: PromoBannerPlaceId;
    type?: TOptional<PromoBannerType>;
  },
  unknown
>;

const PromoBannerPlace = ({ isFrameless, onClick, placeId, type }: TProps) => {
  const props = usePromoBannersProps(placeId, type);
  const trackedOnClick = useCallback(
    (link: Partial<TLink>, clickDetails?: unknown) => {
      onClick?.(link, clickDetails);
      tagmanager.tracker.promoBanners.onClick(props, clickDetails);
    },
    [onClick],
  );

  if (props) {
    const Banner = BANNERS[props.type];
    if (Banner) {
      const banner = <Banner {...props} onClick={trackedOnClick} />;
      if (isFrameless) {
        return banner;
      }
      return <div className={`PromoBannerPlace _${placeId} _${props.type}`}>{banner}</div>;
    }
  }
};

export default dynamic(() => Promise.resolve(PromoBannerPlace));
