import { useCallback } from 'react';

import type { TPromoBannerLeaderboardProps, TWithOnClick } from '@/ducks/promoBanners/types';

const PromoBannerLeaderboard = ({ cta, onClick }: TWithOnClick<TPromoBannerLeaderboardProps>) => {
  const clickCb = useCallback(() => {
    // onClick?.(сta);
  }, [onClick, cta]);

  return (
    <div className="PromoBannerLeaderboard" onClick={clickCb}>
      [PromoBannerLeaderboard]
    </div>
  );
};

export default PromoBannerLeaderboard;
