import { type ReactNode, type RefObject, useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import type { TMediaSrcSet } from '@/infra/types/common';
import type { TOptional } from '@/types/common';

import useAcceptableSrc from './hooks/useAcceptableSrc';

export type TVideoControls = {
  load: () => void;
  pause: () => void;
  play: () => void;
};

export const makeVideoControls = (
  ref: RefObject<HTMLMediaElement>,
  setIsPlayed: (value: boolean) => void,
): TVideoControls => ({
  load: () => ref.current?.load(),
  pause: () => {
    ref.current?.pause();
    setIsPlayed(false);
  },
  play: () => {
    ref.current?.play();
    setIsPlayed(true);
  },
});

type TProps = {
  className?: TOptional<string>;
  fallback?: ReactNode;
  onReady?: TOptional<(controls: TVideoControls) => void>;
  srcSet: TMediaSrcSet;
} & Omit<JSX.IntrinsicElements['video'], 'src'>;

const VideoSet = ({ autoPlay, className, fallback, onReady, srcSet, ...restProps }: TProps) => {
  const refIsReady = useRef<boolean>();
  const [isPlayed, setIsPlayed] = useState<boolean>(!!autoPlay);
  const { ref, src } = useAcceptableSrc<HTMLVideoElement>(srcSet);

  useEffect(() => {
    if (ref.current && !refIsReady.current) {
      refIsReady.current = true;
      onReady?.(makeVideoControls(ref, setIsPlayed));
    }
  }, [ref.current, src]);

  const classes = cn(className, { _playing: isPlayed });
  return !src ? fallback : <video autoPlay={isPlayed} className={classes} ref={ref} src={src} {...restProps} />;
};

export default VideoSet;
