import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { resetAKFlowFully } from '@/components/AccessKeyCard/new/helpers';
import Flyout from '@/components/Flyout';
import FlyOut from '@/components/Icon/FlyOut';
import Trash from '@/components/Icon/Trash';
import { IconButton } from '@/components/elements';
import Button from '@/components/elements/Button';
import { ModalContent, ModalContentBody } from '@/components/elements/ModalContent';
import { getAccessKey, removeActiveAccessKeyAction } from '@/ducks/accessKeys';
import { selectSettings } from '@/ducks/common/settings/selectors';
import { FormattedMessage, useFormattedMessageContext } from '@/helpers/formatted-message';
import { getPromotionTitle } from '@/helpers/util';
import useBodyClassModifier from '@/hooks/useBodyClassModifier';
import { useAppDispatch } from '@/store';
import tagmanager from '@/tagmanager';
import { createTracker } from '@/tagmanager/tracker';

import { AccessKeyFlyout } from './new/Flyout';

import './AccessKeyCard.scss';

const trackEvent = createTracker({ action: 'link-click' });

const AccessKeyCardContent = ({ accessKeyDetails: { promoBenefit, shortDescription, title } }) => (
  <div className="AccessKeyCardNew__content">
    <div className="AccessKeyCardNew__name">
      <span aria-label="" className="AccessKeyCardNew__name__icon" role="img">
        <FormattedMessage defaultMessage="🔑" id="AccessKey.emoji.key" />
      </span>
      <span className="AccessKeyCardNew__name__code">
        {promoBenefit || <FormattedMessage defaultMessage="Book. Share. Toast." id="AccessKey.bookShareToast" />}
      </span>
    </div>
    <span className="AccessKeyCardNew__title">
      {title || <FormattedMessage defaultMessage="Sailing together is so much better!" id="AccessKey.bannerTitle" />}
    </span>
    <span className="AccessKeyCardNew__separator">|</span>
    <span className="AccessKeyCardNew__description">
      {shortDescription || (
        <FormattedMessage
          defaultMessage="Book any cabin on the same sailing as your friend to get bottle of bubbly added to your booking"
          id="AccessKey.bannerMessage"
        />
      )}
    </span>

    <FlyOut />
  </div>
);

AccessKeyCardContent.propTypes = {
  accessKeyDetails: PropTypes.shape({
    promoBenefit: PropTypes.string,
    shortDescription: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

const AccessKeyCardErrorFlyout = ({ clearAccessKey }) => (
  <ModalContent>
    <ModalContentBody>
      <h1>
        <FormattedMessage defaultMessage="Oops!" id="VoyagePlanner.ItineraryResults.accessKeyInvalidPopup.title" />
      </h1>
      <p>
        <FormattedMessage
          defaultMessage="Your access key is no longer valid. You can continue booking without the access key or cancel and go back to the home page."
          id="VoyagePlanner.ItineraryResults.accessKeyInvalidPopup.body"
        />
      </p>
      <div className="accessKeyInvalidPopup__actionButtons">
        <Button
          onClick={() => {
            window.location.href = '/';
          }}
          variant="secondary"
        >
          <FormattedMessage defaultMessage="Cancel" id="VoyagePlanner.ItineraryResults.accessKeyInvalidPopup.cancel" />
        </Button>
        <Button onClick={clearAccessKey} variant="secondary">
          <FormattedMessage
            defaultMessage="Continue"
            id="VoyagePlanner.ItineraryResults.accessKeyInvalidPopup.continue"
          />
        </Button>
      </div>
    </ModalContentBody>
  </ModalContent>
);

AccessKeyCardErrorFlyout.propTypes = {
  clearAccessKey: PropTypes.func.isRequired,
};

const CLEAR_KEY_TEXT_CMS_OPTION = {
  defaultMessage: 'Clear access key',
  id: 'AccessKey.clearCTA',
};

const AccessKeyCard = ({ accessKeyDetails, actions, config: { promotionTitle } }) => {
  const dispatch = useAppDispatch();
  const { accessKey, error, nodeType = '', title = '' } = accessKeyDetails;
  const [isAccessCardFlyoutOpened, setIsAccessCardFlyoutOpened] = useState(false);

  const { formatMessage } = useFormattedMessageContext();
  useBodyClassModifier('-with-access-key', accessKey);

  // Access key was initially invalid
  useEffect(() => {
    if (!!error && accessKey == null) {
      actions.removeActiveAccessKey();
    }
  }, [error, accessKey]);

  const openAccessCardFlyout = useCallback(() => {
    const labelParts = ['tell-me-more'];
    if (title) {
      labelParts.push(title);
    }
    labelParts.push(getPromotionTitle(nodeType, promotionTitle));
    trackEvent({ label: labelParts.join('|') });
    setIsAccessCardFlyoutOpened(true);
  }, [setIsAccessCardFlyoutOpened, promotionTitle, nodeType, title]);

  const closeAccessCardFlyout = useCallback(() => setIsAccessCardFlyoutOpened(false), [setIsAccessCardFlyoutOpened]);

  const clearAccessKey = useCallback(() => {
    const buttonText = formatMessage(CLEAR_KEY_TEXT_CMS_OPTION)?.[0];
    tagmanager.tracker.common.trackIconClick({
      labels: [buttonText],
    });

    dispatch(resetAKFlowFully());
  }, []);

  if (!accessKey) {
    return null;
  }

  if (error) {
    return (
      <Flyout
        className="accessKeyInvalidPopup -adaptive-height"
        direction="modal"
        hideCrossButton
        onDismiss={() => {}}
        open
      >
        <AccessKeyCardErrorFlyout clearAccessKey={clearAccessKey} />
      </Flyout>
    );
  }

  return (
    <>
      <div className="AccessKeyCardNew" id="AccessKeyCard">
        <div className="AccessKeyCardNew__wrapper" onClick={openAccessCardFlyout} role="none">
          <AccessKeyCardContent accessKeyDetails={accessKeyDetails} />
          <AccessKeyCardContent accessKeyDetails={accessKeyDetails} />
        </div>

        <IconButton
          aria-describedby="ClearAccessKey"
          aria-label="Clear"
          border
          className="AccessKeyCardNew__clear-button"
          onClick={clearAccessKey}
        >
          <span className="AccessKeyCardNew__clear-button__label">
            <span className="AccessKeyCardNew__clear-button__label__desktop">
              <FormattedMessage {...CLEAR_KEY_TEXT_CMS_OPTION} />
            </span>
            <span className="AccessKeyCardNew__clear-button__label__mobile">
              <FormattedMessage defaultMessage="Clear" id="AccessKey.clearCTA.short" />
            </span>
          </span>
          <Trash id="Trash" />
        </IconButton>
      </div>

      {isAccessCardFlyoutOpened && (
        <Flyout
          className="AccessKeyFlyoutWrapper"
          direction="right"
          onDismiss={closeAccessCardFlyout}
          open={isAccessCardFlyoutOpened}
        >
          <AccessKeyFlyout accessCardFlyoutData={accessKeyDetails} />
        </Flyout>
      )}
    </>
  );
};

AccessKeyCard.propTypes = {
  accessKeyDetails: PropTypes.shape({
    accessKey: PropTypes.string,
    error: PropTypes.string,
    nodeType: PropTypes.string,
    promoBenefit: PropTypes.string,
    shortDescription: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    removeActiveAccessKey: PropTypes.func,
  }).isRequired,
  config: PropTypes.shape({
    promotionTitle: PropTypes.shape({}),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  accessKeyDetails: getAccessKey(state),
  config: selectSettings(state),
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        removeActiveAccessKey: removeActiveAccessKeyAction,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessKeyCard);
