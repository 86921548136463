import type { TMediaSet } from '@/infra/types/common';

import useSrcSet, { type TUseSrcSet } from './hooks/useSrcSet';

type TProps = Omit<JSX.IntrinsicElements['img'], 'alt' | 'src' | 'srcSet'> & TMediaSet;

const ImageSet = ({ alt, className, srcSet, ...restProps }: TProps) => {
  const { sources, src } = useSrcSet(srcSet) || ({} as TUseSrcSet);
  return (
    !!src && (
      <picture className={className}>
        {sources?.map((attrs) => <source key={attrs.media} {...attrs} />)}
        <img {...restProps} alt={alt || ''} src={src} />
      </picture>
    )
  );
};

export default ImageSet;
