import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import React, { useEffect, useState } from 'react';

import type { UnknownObject } from '@/types/utils';

import { getPaymentBooking } from '@/helpers/managers/payment';
import { getSessionStorageValue } from '@/helpers/util';

const sideqikScript =
  '!function(w,d,s,u,n,t,p){w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},t=d.createElement(s),p=d.getElementsByTagName(s)[0],t.id="sideqik-sdk",t.async=1,t.src=u,p.parentNode.insertBefore(t,p)}(window,document,"script","https://d1hrk5gt3yn7pi.cloudfront.net/api/sideqik-api-1.4.js#5b9043336d8cc10021000759","sideqik");';

const getScriptWithPrimarySailorRevenue = ({
  currency,
  email,
  revenue,
}: Record<'currency' | 'email' | 'revenue', string>) =>
  `!function(w,d,s,u,n,t,p){w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},t=d.createElement(s),p=d.getElementsByTagName(s)[0],t.id='sideqik-sdk',t.async=1,t.src=u,p.parentNode.insertBefore(t,p)}(window,document,"script","https://d1hrk5gt3yn7pi.cloudfront.net/api/sideqik-api-1.4.js#5b9043336d8cc10021000759","sideqik"),sideqik("track","purchase",{revenue:"${revenue}",email:"${email}",currency:"${currency}"});`;

const withSideqikScriptForBookingFlow = (WrappedComponent: React.ElementType, { usePrimarySailorRevenue = false }) => {
  const Wrapped = (props: UnknownObject) => {
    const searchParams = useSearchParams();
    const [revenue, setRevenue] = useState('');
    const [currency, setCurrency] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
      if (usePrimarySailorRevenue) {
        const primarySailor = getSessionStorageValue('primarySailor') || '';
        const bookNowData = getPaymentBooking();
        setCurrency(searchParams.get('currencyCode') as string);
        setEmail(primarySailor?.email);
        setRevenue(bookNowData?.totalAmount);
      }
    }, []);

    return (
      <>
        <Script id="withSideqikScriptForBookingFlow">
          {!usePrimarySailorRevenue ? sideqikScript : getScriptWithPrimarySailorRevenue({ currency, email, revenue })}
        </Script>
        <WrappedComponent {...props} />
      </>
    );
  };
  return Wrapped;
};

export default withSideqikScriptForBookingFlow;
