import { useCallback } from 'react';

import Icon from '@publicImages/svg/circle-arrow.svg?icon';

import type { TPromoBannerMarqueeProps, TWithOnClick } from '@/ducks/promoBanners/types';

import Eyebrow from '../Eyebrow';

import styles from './PromoBannerMarquee.module.scss';

const PromoBannerMarquee = ({ description, eyebrow, onClick, title, url }: TWithOnClick<TPromoBannerMarqueeProps>) => {
  const hasEyebrow = !!eyebrow;
  const hasTitle = !!title;
  const hasDescription = !!description;
  const hasContent = hasEyebrow || hasTitle || hasDescription;

  const clickCb = useCallback(() => {
    onClick?.({ label: (title || description || eyebrow?.tag || eyebrow?.text)!, url: url! });
  }, [description, eyebrow, onClick, title, url]);

  const isLink = !!url && hasContent;
  const Node = isLink ? 'a' : 'div';
  const nodeProps = isLink ? { href: url!, onClick: clickCb, target: '_blank' } : undefined;

  return (
    <Node className={styles.root} {...nodeProps}>
      {hasContent && (
        <div className={styles.track}>
          <div className={styles.content}>
            {hasEyebrow && <Eyebrow className={styles.eyebrow} {...eyebrow} isOmitPastCountdown />}
            {hasTitle && <h3 className={styles.title}>{title}</h3>}
            {hasTitle && hasDescription && '|'}
            {hasDescription && <div className={styles.description}>{description}</div>}
            {isLink && <Icon className={styles.icon} />}
          </div>
        </div>
      )}
    </Node>
  );
};

export default PromoBannerMarquee;
