import { useCallback } from 'react';

import cn from 'classnames';

import Arrow from '@publicImages/svg/arrow-right.svg?icon';

import ImageSet from '@/components/Media/ImageSet';
import {
  PromoBannerContentAlign,
  PromoBannerDoubleAppearance,
  PromoBannerDoubleOverlayColor,
  type TPromoBannerDoubleClickDetails,
  type TPromoBannerDoubleProps,
  type TWithOnClick,
} from '@/ducks/promoBanners/types';

import SvgPortHole from './svg/port-hole.svg?static';
import SvgVStyle from './svg/v-style.svg?static';

type HalfBannerProps = { right?: boolean } & TWithOnClick<
  Pick<TPromoBannerDoubleProps, 'appearance' | 'overlayColor'> & TPromoBannerDoubleProps['one'],
  TPromoBannerDoubleClickDetails
>;

const HalfBanner = ({
  appearance,
  contentAlign,
  cta,
  imageSet,
  onClick,
  overlayColor,
  right,
  title,
}: HalfBannerProps) => {
  const hasUrl = !!cta?.url;
  const hasTitle = !!(title || cta?.label);

  const clickCb = useCallback(
    () => onClick?.({ label: cta?.label || title, url: cta?.url }, { half: !right ? 'one' : 'two' }),
    [cta, onClick, title],
  );

  const className = cn('PromoBannerDouble__HalfBanner', {
    _contentAlignBottomLeft: contentAlign === PromoBannerContentAlign.bottomLeft,
    _contentAlignBottomRight: contentAlign === PromoBannerContentAlign.bottomRight,
    _contentAlignTopLeft: contentAlign === PromoBannerContentAlign.topLeft,
    _contentAlignTopRight: contentAlign === PromoBannerContentAlign.topRight,
    _left: !right,
    _overlayColorOceanBlue: overlayColor === PromoBannerDoubleOverlayColor.OceanBlue,
    _overlayColorRockstar: overlayColor === PromoBannerDoubleOverlayColor.Rockstar,
    _overlayColorRockstarDark: overlayColor === PromoBannerDoubleOverlayColor.RockstarDark,
    _overlayColorSquid: overlayColor === PromoBannerDoubleOverlayColor.Squid,
    _overlayColorUplift: overlayColor === PromoBannerDoubleOverlayColor.Uplift,
    _overlayColorVirginRed: overlayColor === PromoBannerDoubleOverlayColor.VirginRed,
    _overlayColorVirginRedDark: overlayColor === PromoBannerDoubleOverlayColor.VirginRedDark,
    _right: right,
  });

  if (!hasUrl && !hasTitle) return;

  return (
    <section className={className} tabIndex={0}>
      {imageSet && <ImageSet className="PromoBannerDouble__HalfBanner__image" {...imageSet} />}
      {appearance === PromoBannerDoubleAppearance.portHole && (
        <SvgPortHole className="PromoBannerDouble__Appearance PromoBannerDouble__Appearance__PortHole" />
      )}
      {appearance === PromoBannerDoubleAppearance.vStyle && (
        <SvgVStyle
          className={cn(
            'PromoBannerDouble__Appearance',
            'PromoBannerDouble__Appearance__VStyle',
            `PromoBannerDouble__Appearance__VStyle__${right ? 'right' : 'left'}`,
          )}
        />
      )}
      {hasTitle && <div className="PromoBannerDouble__HalfBanner__label">{title || cta?.label}</div>}
      {hasUrl && (
        <a className="PromoBannerDouble__HalfBanner__arrow" href={cta?.url} onClick={clickCb} target="_blank">
          <Arrow />
        </a>
      )}
    </section>
  );
};

export default HalfBanner;
