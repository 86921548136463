import { useMemo } from 'react';

import cn from 'classnames';

import VVLogo from '@publicImages/svg/V-strapline-white.svg?static';

import type { TOptional } from '@/types';

import UIResource from '@/components/UIResource';
import * as Dialog from '@/components/elements/Dialog';
import { useGetSpecialSailings } from '@/ducks/lookup/hooks/useGetSpecialSailings';

import classes from './SpecialSailingModal.module.scss';

export const SpecialSailingModal = ({ classificationCode }: { classificationCode: TOptional<string> }) => {
  const specialSailings = useGetSpecialSailings();
  const specialSailing = useMemo(
    () => specialSailings?.find((sailing) => sailing.id === classificationCode),
    [classificationCode, specialSailings],
  );

  if (specialSailing == null) return;

  const { mobileModalHero, modalDescription, name: title, portraitCarousel, subtitle } = specialSailing;

  const modalDescriptionClasses = cn({
    [classes.description]: true,
    [classes.textCenter]: modalDescription && !modalDescription.includes('<br />'),
  });

  return (
    <div className={classes.root}>
      <div className={classes.textContent}>
        <div className={classes.logo}>
          <VVLogo />
        </div>
        <Dialog.Title asChild>
          <h1 className={classes.title}>
            <UIResource id="SpecialSailingModal.VirginVoyages.name" />
            <br />
            {title}
          </h1>
        </Dialog.Title>
        <Dialog.Description asChild>
          <h2 className={classes.subTitle}>{subtitle}</h2>
        </Dialog.Description>
        {modalDescription && (
          <div className={modalDescriptionClasses} dangerouslySetInnerHTML={{ __html: modalDescription }} />
        )}
      </div>
      <div
        className={classes.backgroundImage}
        style={{
          '--desktop-image': `url(${portraitCarousel[0]?.src})`,
          '--mobile-image': `url(${mobileModalHero?.src})`,
        }}
      />
    </div>
  );
};
