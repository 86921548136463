import { useMemo } from 'react';

import { useGetBFResources } from '@/ducks/programs/blackFriday/hooks/useGetBFResources';

export const ApplyBFColors = () => {
  const bfResources = useGetBFResources();

  const colorMap = useMemo(() => getColorMap(bfResources), [bfResources]);

  return <style>{colorMap}</style>;
};

const getColorMap = (bfResources) => {
  const isAnyColor = bfResources?.colorSwatch1;
  if (!isAnyColor) {
    return '';
  }

  const colorMap = Object.entries(bfResources).reduce((result, [key, value]) => {
    const colorString = key.split('colorSwatch');
    const colorIndex = colorString?.[1];
    if (!colorIndex) {
      return result;
    }

    return (
      result +
      `--bf-color-${colorIndex}: ${value};
    `
    );
  }, '');

  return `
    :root {
      ${colorMap}
    }
  `;
};
