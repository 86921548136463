import { useMemo } from 'react';

import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import { useGetBFResources } from '@/ducks/programs/blackFriday/hooks/useGetBFResources';

import useBlackFridayIsEnabled from './useBlackFridayIsEnabled';

type Sailing = Pick<SailingFromPackages, 'endDate' | 'id' | 'startDate'>;

const useIsBlackFridaySailing = (sailingData: Sailing | Sailing[]): boolean => {
  const isBlackFridayEnabled = useBlackFridayIsEnabled();
  const { sailingsToBeExcluded, voyageEnd: toDate, voyageStart: fromDate } = useGetBFResources();
  return useMemo(() => {
    if (isBlackFridayEnabled && sailingData) {
      const sailings = Array.isArray(sailingData) ? sailingData : [sailingData];
      const involvedSailings = sailingsToBeExcluded?.length
        ? sailings.filter(({ id }) => !sailingsToBeExcluded.includes(id))
        : sailings;
      if (involvedSailings.length && (fromDate || toDate)) {
        const start = fromDate ? new Date(fromDate) : undefined;
        const end = toDate ? new Date(toDate) : undefined;
        return involvedSailings?.some(
          ({ endDate, startDate }) => (!start || start <= new Date(startDate)) && (!end || new Date(endDate) <= end),
        );
      }
      return !!involvedSailings.length;
    }
    return false;
  }, [fromDate, isBlackFridayEnabled, sailingData, sailingsToBeExcluded, toDate]);
};

export default useIsBlackFridaySailing;
