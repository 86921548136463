import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import qs from 'query-string';

import { buildUrlPath, routes } from '@/ducks/routes';

const usePrecheckoutUrlWithParams = (sailing) => {
  const searchParams = useSearchParams();
  return useMemo(
    () =>
      buildUrlPath({
        hash: false,
        pathname: routes.planner.preCheckOut.path,
        query: {
          ...qs.parse(searchParams.toString()),
          packageCode: sailing?.packageCode,
          shipCode: sailing?.ship?.code,
          voyageId: sailing?.id,
        },
      }),
    [sailing, searchParams],
  );
};

export default usePrecheckoutUrlWithParams;
