import { useMemo } from 'react';

import type { TMediaSrcSet } from '@/infra/types/common';
import type { TOptional } from '@/types/common';

import getOrderedNumericKeys from '../../helpers/getOrderedNumericKeys';

export type TUseSrcSet = {
  sources: Array<{ media: string; srcSet: string }>;
  src: string;
};

const useSrcSet = (srcSet: TMediaSrcSet): TOptional<TUseSrcSet> =>
  useMemo(() => {
    const sizes = getOrderedNumericKeys(srcSet).reverse();
    if (sizes.length) {
      const minSize = sizes.pop();
      return {
        sources: sizes.map((size) => ({ media: `(min-width: ${size}px)`, srcSet: srcSet[`${size!}`]! })),
        src: srcSet[`${minSize!}`]!,
      };
    }
  }, [srcSet]);

export default useSrcSet;
