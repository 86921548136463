import { type RefObject, useEffect, useMemo, useRef, useState } from 'react';

import type { TMediaSrcSet } from '@/infra/types/common';
import type { TOptional } from '@/types/common';

import useOnWindowResize from '@/hooks/useOnWindowResize';

import getOrderedNumericKeys from '../../helpers/getOrderedNumericKeys';

export type TUseAcceptableSrc<E> = {
  ref: RefObject<E>;
  src: TOptional<string>;
};

const useAcceptableSrc = <E extends HTMLImageElement | HTMLMediaElement>(
  srcSet: TMediaSrcSet,
): TUseAcceptableSrc<E> => {
  const [failedUrls, setFailedUrls] = useState<string[]>();
  const ref = useRef<E>(null);
  const refListener = useRef<(url: string) => void>();

  useEffect(() => {
    refListener.current = (url: string) => {
      if (!failedUrls?.includes(url)) setFailedUrls([...(failedUrls || []), url]);
    };
  }, [failedUrls]);

  useEffect(() => {
    const listener = (event: Event) => {
      const { currentSrc } = (event.target || {}) as HTMLImageElement | HTMLMediaElement;
      if (currentSrc) refListener.current?.(currentSrc);
    };
    const { current: node } = ref;
    if (node) {
      node.addEventListener('error', listener);
      return () => {
        node.removeEventListener('error', listener);
      };
    }
  }, [ref.current]);

  const src = useOnWindowResize<TOptional<string>>(
    ({ width }) => {
      const sizes = getOrderedNumericKeys(srcSet).reverse();
      const size = sizes.find((size) => size <= width && !failedUrls?.includes(srcSet[`${size!}`]!));
      if (size) return srcSet[`${size!}`]!;
    },
    [failedUrls, srcSet],
  );

  return useMemo(() => ({ ref, src }), [src]);
};

export default useAcceptableSrc;
