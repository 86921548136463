import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { Accordion } from '@/components/elements';
import { env } from '@/environment';
import { FormattedMessage, useFormattedMessageContext } from '@/helpers/formatted-message';
import { decodeInnerHTML } from '@/helpers/util';

import './AccessKeyFlyout.scss';

export const AccessKeyFlyout = (props) => {
  const { formatMessage } = useFormattedMessageContext();
  const image = get(props, 'accessCardFlyoutData.smallImage', {});
  const headline = get(props, 'accessCardFlyoutData.headline', '');
  const promoBenefit = get(props, 'accessCardFlyoutData.promoBenefit', '');
  const legalContent = get(props, 'accessCardFlyoutData.legalContent', {});
  const defaultAccessKeyFlyoutImage = formatMessage({
    defaultMessage: {
      alt: '',
      src: `${env.VV_BASE_URL}dam/jcr:61b2ab7c-d481-4e3a-b4b7-90c1df8dc7ad/FPO_80x80.png`,
    },
    id: 'AccessKey.flyoutImage',
  });
  const defaultLongDescription = formatMessage({
    defaultMessage:
      'With a vibrant home port right in your backyard, you don’t need to travel far to have an adventure. Set out to explore the Caribbean and get an extra 15% off all voyages sailing within 45 days, with our exclusive Florida Resident Rate.',
    id: 'AccessKey.flyoutMessage',
  }).join('');

  return (
    <div className="AccessCard">
      <section className="FlyoutContent">
        <div className="header" style={{ backgroundImage: `url(${image.src || defaultAccessKeyFlyoutImage.src})` }}>
          <p>{headline}</p>
          <h3 className="AccessCard__Heading">{promoBenefit.toUpperCase()}</h3>
        </div>
        <div className="FlyoutContent__section AccessCard__Description">
          <h3 className="AccessCard__Heading">
            {legalContent.title || (
              <FormattedMessage
                defaultMessage="Sea the Caribbean with an extra 15% off."
                id="AccessKey.flyoutHeading"
              />
            )}
          </h3>
          <p className="AccessCard__Description-LongDesc">
            <span>
              {decodeInnerHTML((legalContent?.fullContent || defaultLongDescription).replace(/\n/g, '<br />'))}
            </span>
          </p>
        </div>
        {!isEmpty(legalContent.blocks) && (
          <div className="FlyoutContent__section AccessCard__Blocks">
            <Accordion accordionItems={legalContent.blocks} />
          </div>
        )}
      </section>
    </div>
  );
};

AccessKeyFlyout.propTypes = {
  accessCardFlyoutData: PropTypes.shape({}),
};

AccessKeyFlyout.defaultProps = {
  accessCardFlyoutData: {},
};
