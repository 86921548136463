import type { AppDispatch } from '@/store';

import { removeActiveAccessKeyAction } from '@/ducks/accessKeys';
import { applyFilters, resetFiltersForPackages } from '@/ducks/pages/chooseVoyage';
import { getPathname, getRouter } from '@/ducks/routes/history';

export const resetAKFlowFully = () => (dispatch: AppDispatch) => {
  dispatch(removeActiveAccessKeyAction(false));
  const router = getRouter();
  const pathname = getPathname();
  router.replace(pathname); // clear all search params before using default filter values
  dispatch(applyFilters({ fetch: true }));
};

export const resetAKFlowSearchCriteria = () => (dispatch: AppDispatch) => {
  dispatch(resetFiltersForPackages());
};
