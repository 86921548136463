import { useCallback } from 'react';

import cn from 'classnames';

import Icon from '@publicImages/svg/arrow-right-2.svg?icon';

import type { TPromoBannerCtaLink, TWithOnClick } from '@/ducks/promoBanners/types';
import type { TOptional } from '@/types/common';

import Button from '@/components/elements/Button';

import styles from './PromoBannerCTA.module.scss';

export const LONG_LABEL_LENGTH = 25;

type TProps = Omit<TPromoBannerCtaLink, 'label' | 'url'> &
  Required<Pick<TPromoBannerCtaLink, 'label' | 'url'>> &
  TWithOnClick<{
    className?: TOptional<string>;
  }>;

const PromoBannerCTA = ({ appearance, className, label, onClick, url }: TProps) => {
  const isButton = appearance === 'prominent';

  const clickCb = useCallback(() => {
    if (isButton) window.open(url!, '_blank');
    onClick?.({ label: label!, url: url! });
  }, [isButton, label, url, onClick]);

  const isLongText = label?.length > LONG_LABEL_LENGTH;

  return (
    <div className={cn(styles.root, className)}>
      {!isButton && (
        <a className={cn(styles.link, { _longText: isLongText })} href={url!} onClick={clickCb} target="_blank">
          {label}
          <Icon className={styles.icon} />
        </a>
      )}
      {isButton && (
        <Button className={cn(styles.button, { _longText: isLongText })} onClick={clickCb} variant="primary">
          {label}
        </Button>
      )}
    </div>
  );
};

export default PromoBannerCTA;
